import { extractLinkInfo } from '/machinery/extractLinkInfo'

import { Button } from '/features/buildingBlocks/Button'
import { Image } from '/features/buildingBlocks/Image'
import { HeadingXl } from '/features/buildingBlocks/Heading'

import styles from './CardsWithCta.css'

export function CardsWithCta({ items, title = undefined, layoutClassName = undefined }) {
  return (
    <section className={cx(styles.component, layoutClassName)}>
      {title && (
        <header className={styles.header}>
          <HeadingXl layoutClassName={styles.titleLayout} h='3'>{title}</HeadingXl>
        </header>
      )}
      <div className={styles.cardsContainer}>
        {items?.map((item, index) => <Card key={index} layoutClassName={styles.cardLayout} {...{ item }} />)}
      </div>
    </section>
  )
}

function Card({ item, layoutClassName = undefined }) {
  const { title, description, button, image } = item ?? {}
  const { buttonLabel: label, internalOrExternalLink } = button || {}
  const { href } = extractLinkInfo(internalOrExternalLink)
  const target = href?.startsWith('/') ? '_self' : '_blank'

  return (
    <a data-x='link-to-card-page' className={cx(styles.componentCard, layoutClassName)} {...{ href, target }}>
      {image?.asset && (
        <figure className={styles.imageContainer}>
          <Image imgProps={{ loading: 'lazy' }} {...{ image }} />
          {image?.alt && <figcaption className={styles.imageContainerFigCaption}>{image.alt}</figcaption>}
        </figure>
      )}
      <div className={styles.contentContainer}>
        {title && <h3 className={styles.cardTitle}>{title}</h3>}
        {description && <p>{description}</p>}
      </div>
      {button && <Button dataX='click-to-read-more' layoutClassName={styles.buttonLayout}>{label}</Button>}
    </a>
  )
}
